/** @format */

export default {
  data() {
    return {
      idItemShow: 0,
      showDetail: false,
    };
  },
  computed: {
    configHead() {
      return this.m.config.default || {};
    },
    url() {
      let res;
      if (typeof this.m?.api == 'object') {
        res = this.m.api?.[this.$root.profile.role] || this.m.api.default;
      } else res = this.m?.api;
      return res;
      return this.configRole.api;
    },
    api() {
      let res;
      if (typeof this.m?.api == 'object') {
        res = this.m.api?.[this.$root.profile.role] || this.m.api.default;
      } else res = this.m?.api;
      return res;
      return this.configRole.api;
    },
  },
  watch: {
    showDetail(v) {
      if (!v) {
        this.idItemShow = 0;
        this.$emit('onClose');
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
  },
};
